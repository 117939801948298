import { useState } from 'react';
import { useRouter } from 'next/router';

import { Btn } from '@playbooks/interface/buttons';
import { Form } from '@playbooks/interface/forms';
import { Section, SectionBody, SectionFooter, SectionHeader, SectionTitle } from '@playbooks/interface/sections';
import { LegalDisclaimer } from 'components/auth/legal-disclaimer';
import { LoginFields } from 'components/auth/login-fields';
import { GithubOAuthBtn } from 'components/oauth/github-oauth-btn';
import { useStore } from 'contexts';
import { useAction } from 'hooks';

const LoginForm = ({ onNext }) => {
	const [user, setUser] = useState<any>({});
	const router = useRouter();
	const store = useStore();

	// Hooks
	const [onSave, saveTask] = useAction({}, async () => {
		const response = await store.createRecord({ url: '/auth/login', data: user });
		await onProcess(response.data);
	});

	// Methods
	const onProcess = async data => {
		['active', 'hidden'].includes(data.status) ? await onNext(data) : router.push(`/verify?email=${data.email}`);
	};

	const onSubmit = e => {
		e.preventDefault();
		onSave();
	};

	// Render
	return (
		<Form onSubmit={onSubmit}>
			<Section>
				<SectionHeader>
					<SectionTitle>Login</SectionTitle>
				</SectionHeader>
				<SectionBody size='sm'>
					<LoginFields user={user} setUser={setUser} />
				</SectionBody>
				<SectionFooter display='block' space='space-y-4'>
					<Btn type='submit' size='lg' width='w-full' taskRunning={saveTask.running}>
						Login
					</Btn>
					<GithubOAuthBtn onNext={onProcess} />
					<LegalDisclaimer />
				</SectionFooter>
			</Section>
		</Form>
	);
};

export { LoginForm };
