import { useEffect } from 'react';

import { Container } from '@playbooks/interface/grid';
import { Div, Span } from '@playbooks/interface/html';
import { TextLink } from '@playbooks/interface/links';
import { AuthWrapper } from 'components/auth/auth-wrapper';
import { LoginForm } from 'components/auth/login-form';
import { useMixpanel, useStorage } from 'contexts';

const LoginRoute = ({ ssr, account, router, session, store, toast }) => {
	const mixpanel = useMixpanel();
	const storage = useStorage();

	// Hooks
	useEffect(() => {
		if (router.query.redirect) {
			storage.storeValue(`redirect`, router.query.redirect);
			router.replace({ query: {} });
		}
	}, [router.query.redirect]);

	useEffect(() => {
		if (session.isAuthenticated()) {
			toast.showSuccess(200, 'Success!', `Welcome to Playbooks, ${session.user?.firstName}.`);
			router.push(storage.storage?.redirect ? storage.storage?.redirect : `/home`);
		}
	}, [session.isAuthenticated()]);

	// Methods
	const onNext = async data => {
		mixpanel.trackEvent('Login', data);
		return await session.onAuth(data);
	};

	// Render
	return (
		<AuthWrapper>
			<Container size='xs'>
				<LoginForm onNext={onNext} />
				<Div className='text-center'>
					<Span color='gray-500 dark:gray-300'>
						Don't have an account?
						<TextLink size='' href='/register' color='blue-500 dark:cyan-500' className='ml-2'>
							Register here
						</TextLink>
					</Span>
				</Div>
			</Container>
		</AuthWrapper>
	);
};

export default LoginRoute;
