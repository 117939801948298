import { Navbar, NavbarBrand, NavbarList } from '@playbooks/interface/navbars';
import { NavItem } from '@playbooks/interface/navs';
import { AccountDrop } from 'components/account/account-drop';
import { useTheme } from 'contexts';

const AuthNav = () => {
	const theme = useTheme();

	// Computes
	const BrandLogo = '/branding/playbooks-logo.svg';
	const BrandLogoReversed = '/branding/playbooks-logo-dark.svg';

	// Render
	return (
		<Navbar border='border-b'>
			<NavbarBrand src={theme.isDark ? BrandLogoReversed : BrandLogo} alt='Brand Logo' />
			<NavbarList display='hidden lg:flex-end'>
				<NavItem>
					<AccountDrop />
				</NavItem>
			</NavbarList>
		</Navbar>
	);
};

export { AuthNav };
