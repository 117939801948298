import { Footer, FooterItem, FooterLink, FooterList, FooterText } from '@playbooks/interface/footers';
import { Col, Container, Grid } from '@playbooks/interface/grid';

const AuthFooter = ({ ...props }) => {
	// Render
	return (
		<Footer border='border-t' {...props}>
			<Container>
				<Grid>
					<Col sm='6' className='sm:flex-start'>
						<FooterText color='gray-500' fontSize='text-sm'>
							{new Date().getFullYear()} © Playbooks, Inc
						</FooterText>
					</Col>

					<Col sm='6' display='sm:flex-end'>
						<FooterList display='flex-start sm:flex-end' space='space-x-4'>
							<FooterItem>
								<FooterLink size='xs' nextIcon='arrow-up-right' href='/terms' target='_blank'>
									Terms
								</FooterLink>
							</FooterItem>
							<FooterItem>
								<FooterLink size='xs' nextIcon='arrow-up-right' href='/privacy' target='_blank'>
									Privacy
								</FooterLink>
							</FooterItem>
						</FooterList>
					</Col>
				</Grid>
			</Container>
		</Footer>
	);
};

export { AuthFooter };
