import { Main } from '@playbooks/interface/html';
import { Route } from '@playbooks/interface/routes';
import { AuthFooter } from 'components/auth/auth-footer';
import { AuthNav } from 'components/auth/auth-nav';

export const AuthWrapper = ({ title, children }: { title?: string; children: any }) => {
	const props = {
		display: 'flex-column',
		bgColor: 'bg-white dark:bg-gray-900',
		height: 'min-h-[100vh]',
	};

	// Render
	return (
		<Route title={title} {...props}>
			<AuthNav />
			<Main className='grow h-full py-8'>{children}</Main>
			<AuthFooter />
		</Route>
	);
};
